var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modalDataChangeTabsLeads",attrs:{"title-html":_vm.$tc('Transferir o {type} {name} para o separador', _vm.totalSelected, { name: '<b>' + _vm.nameLead + '</b>', type: _vm.nameTypeReg }),"cancel-variant":"outline-secondary","size":"lg","body-class":"","visible":false,"ok-only":false,"ok-title":_vm.$t('Transferir'),"cancel-title":_vm.$t('Fechar'),"ok-variant":"primary","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":"","cancel-disabled":_vm.showProcessing,"ok-disabled":_vm.showProcessing},on:{"ok":_vm.saveModal,"cancel":_vm.hideModal}},[_c('b-overlay',{attrs:{"show":_vm.showProcessing,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('p',{attrs:{"id":"cancel-label"}},[_vm._v(" "+_vm._s(_vm.$t('Aguarde, a processar...'))+" ")])])]},proxy:true}])},[((_vm.mode==='users') && (_vm.usersRspLead.length > 0))?[_c('validation-observer',{ref:"formDataChangeTabsLeads",scopedSlots:_vm._u([{key:"default",fn:function(ref){return _vm._l((_vm.usersRspLead),function(rowUser){return _c('b-row',{key:("user-rsp-lead-" + (rowUser.sw069s03))},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":((rowUser.nome) + " (" + (_vm.$t('Responsável')) + ")")}},[(Array.isArray(_vm.keyReg)===true)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":rowUser.tabs_detail,"append-to-body":"","calculate-position":_vm.withPopper,"clearable":true,"placeholder":_vm.$t('Escolha um separador'),"label":"txt","item-text":"txt","item-value":"tab"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var txt = ref.txt;
return [_vm._v(" "+_vm._s(txt)+" ")]}}],null,true),model:{value:(_vm.changeTab[rowUser.sw069s03]),callback:function ($$v) {_vm.$set(_vm.changeTab, rowUser.sw069s03, $$v)},expression:"changeTab[rowUser.sw069s03]"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])]):_vm._e(),(Array.isArray(_vm.keyReg)===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":rowUser.tabs_detail,"append-to-body":"","calculate-position":_vm.withPopper,"clearable":true,"placeholder":_vm.$t('Escolha um separador'),"label":"txt","item-text":"txt","item-value":"tab"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var txt = ref.txt;
return [_vm._v(" "+_vm._s(txt)+" ")]}}],null,true),model:{value:(_vm.changeTab[rowUser.sw069s01]),callback:function ($$v) {_vm.$set(_vm.changeTab, rowUser.sw069s01, $$v)},expression:"changeTab[rowUser.sw069s01]"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])]):_vm._e()],1)]}}],null,true)})],1)],1)})}}],null,false,3595436970)})]:_vm._e(),((_vm.mode==='tabs') && (_vm.tabs.length > 0))?[_c('validation-observer',{ref:"formDataChangeTabsLeads",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":_vm.$t('Separador')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.tabs,"append-to-body":"","calculate-position":_vm.withPopper,"clearable":true,"placeholder":_vm.$t('Escolha um separador'),"label":"txt","item-text":"txt","item-value":"tab"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var txt = ref.txt;
return [_vm._v(" "+_vm._s(txt)+" ")]}}],null,true),model:{value:(_vm.sw025s188),callback:function ($$v) {_vm.sw025s188=$$v},expression:"sw025s188"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Sem resultados'))+" ")])])],1)]}}],null,true)})],1)],1)]}}],null,false,756771378)})]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }