<template>
  <div>
    <b-modal
      ref="modalDataChangeTabsLeads"
      :title-html="$tc('Transferir o {type} {name} para o separador', totalSelected, { name: '<b>' + nameLead + '</b>', type: nameTypeReg })"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="false"
      :ok-title="$t('Transferir')"
      :cancel-title="$t('Fechar')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      :cancel-disabled="showProcessing"
      :ok-disabled="showProcessing"
      @ok="saveModal"
      @cancel="hideModal"
    >
      <b-overlay
        :show="showProcessing"
        rounded="sm"
      >
        <template v-if="(mode==='users') && (usersRspLead.length > 0)">
          <validation-observer
            ref="formDataChangeTabsLeads"
            #default="{}"
          >
            <b-row
              v-for="(rowUser) in usersRspLead"
              :key="`user-rsp-lead-${rowUser.sw069s03}`"
            >
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :label="`${rowUser.nome} (${$t('Responsável')})`"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-if="Array.isArray(keyReg)===true"
                      v-model="changeTab[rowUser.sw069s03]"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="rowUser.tabs_detail"
                      append-to-body
                      :calculate-position="withPopper"
                      :clearable="true"
                      :placeholder="$t('Escolha um separador')"
                      label="txt"
                      item-text="txt"
                      item-value="tab"
                    >
                      <template #option="{ txt }">
                        {{ txt }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>

                    <v-select
                      v-if="Array.isArray(keyReg)===false"
                      v-model="changeTab[rowUser.sw069s01]"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="rowUser.tabs_detail"
                      append-to-body
                      :calculate-position="withPopper"
                      :clearable="true"
                      :placeholder="$t('Escolha um separador')"
                      label="txt"
                      item-text="txt"
                      item-value="tab"
                    >
                      <template #option="{ txt }">
                        {{ txt }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>
        </template>

        <template v-if="(mode==='tabs') && (tabs.length > 0)">
          <validation-observer
            ref="formDataChangeTabsLeads"
            #default="{}"
          >
            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Separador')"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-model="sw025s188"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="tabs"
                      append-to-body
                      :calculate-position="withPopper"
                      :clearable="true"
                      :placeholder="$t('Escolha um separador')"
                      label="txt"
                      item-text="txt"
                      item-value="tab"
                    >
                      <template #option="{ txt }">
                        {{ txt }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>
        </template>

        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              {{ $t('Aguarde, a processar...') }}
            </p>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import checkNested from 'check-nested'
import btoa from 'btoa'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BOverlay,
  },
  mixins: [mixFormGeral],
  data() {
    return {
      nameLead: null,
      keyReg: null,
      usersRspLead: [],
      tabs: [],
      changeTab: [],
      chgTab_sw069s03: [],
      sw025s188: null,
      mode: null,
      showProcessing: false,
      nameTypeReg: '',
      totalSelected: 0,
    }
  },
  methods: {
    showModal(params) {
      this.resetFields()
      this.nameLead = params.nameLead || ''
      this.keyReg = params.keyReg || null
      this.usersRspLead = params.usersRspLead || []
      this.tabs = params.tabs || []
      this.mode = params.mode || null
      this.nameTypeReg = params.nameTypeReg || ''
      this.totalSelected = params.totalSelected || 0

      if (this.usersRspLead.length > 0) {
        this.usersRspLead.forEach((row, index) => {
          if (Array.isArray(this.keyReg) === true) {
            this.changeTab[row.sw069s03] = row.tabs_detail.find(o => o.tab === row.sw069s07)
          } else {
            this.changeTab[row.sw069s01] = row.tabs_detail.find(o => o.tab === row.sw069s07)
          }

          this.chgTab_sw069s03[index] = btoa(row.sw069s03)
        })
      }

      this.$refs.modalDataChangeTabsLeads.show()
    },

    async hideModal() {
      this.resetFields()
      this.$refs.modalDataChangeTabsLeads.hide()
    },

    resetFields() {
      this.nameLead = null
      this.keyReg = null
      this.usersRspLead = []
      this.tabs = []
      this.changeTab = []
      this.chgTab_sw069s03 = []
      this.sw025s188 = null
      this.mode = null
      this.showProcessing = false
      this.nameTypeReg = ''
      this.totalSelected = 0
    },

    async saveModal(evt) {
      evt.preventDefault()

      await this.$refs.formDataChangeTabsLeads.validate()
        .then(async valid => {
          if (valid === true) {
            this.showProcessing = true

            let oParamsSave = {
              sw025s01: this.keyReg,
              fields: this.changeTab,
              usersShare: this.chgTab_sw069s03,
              type: this.nameTypeReg,
            }

            if (this.mode === 'tabs') {
              oParamsSave = {
                sw025s01: this.keyReg,
                sw025s188: ((checkNested(this.sw025s188, 'tab') ? this.sw025s188.tab : '')),
                type: this.nameTypeReg,
              }
            }

            await this.$store.dispatch('crm_leads_events/saveChangeTabUsersRspLeadByTeam', oParamsSave).then(async msgSuccess => {
              this.showProcessing = false

              if (msgSuccess) {
                await this.showMsgSuccessRequest({ message: msgSuccess }).then(async () => {
                  this.hideModal()
                  await this.$parent.showTabsListviewWithRecords()
                  await this.$parent.fetchListView()
                })
              }
            }).catch(error => {
              this.showProcessing = false
              this.showMsgErrorRequest(error)
            })
          } else if (valid === false) {
            if (this.mode === 'tabs') {
              this.showMsgErrorRequest({ message: this.$t('Tem de escolher o separador') })
            } else {
              this.showMsgErrorRequest({ message: this.$t('Tem de escolher o separador para o responsável') })
            }
          }
        })
    },
  },
}
</script>
